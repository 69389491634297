import flatten from 'lodash/flatten';
import { useState, useEffect, useCallback } from 'react';
const PREFIX = 'components: WidgetPreviewContainer: useWidgetPreviewResolution:';
const EMPTY_RESOLUTION = {
  id: 'empty',
  value: [0, 0],
  label: ''
}; // Sync with ./styles.module.scss

const CONTAINER_HORIZONTAL_PADDING = 50;
export default function useWidgetPreviewResolution(_ref) {
  let {
    previewGroups,
    containerRef,
    initialResolutionStrategy = 'detect',
    defaultPreviewGroup = null,
    defaultPreviewResolution,
    defaultZoomLevel = 100,
    onPreviewResolutionChange
  } = _ref;
  const {
    0: activePreviewGroup,
    1: setActivePreviewGroup
  } = useState(defaultPreviewGroup || null);
  const {
    0: previewResolution,
    1: setResolution
  } = useState(EMPTY_RESOLUTION);
  const {
    0: zoomLevel,
    1: setZoomLevel
  } = useState(defaultZoomLevel); // Detect optimial initial resolution
  // when inital strategy is detection

  useEffect(() => {
    if (initialResolutionStrategy !== 'detect') return;
    if (containerRef.current instanceof HTMLElement === false) return;
    const maxDisoverWidth = Math.round(containerRef.current.getBoundingClientRect().width);
    const updatedResolution = discoverClosestResolution(previewGroups, maxDisoverWidth, defaultPreviewResolution);
    setResolution(updatedResolution);
    setActivePreviewGroup(findResolutionsGroup(previewGroups, updatedResolution) || defaultPreviewGroup); // Broadcast updated resolution

    if (updatedResolution && onPreviewResolutionChange) {
      onPreviewResolutionChange(updatedResolution);
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [initialResolutionStrategy, containerRef.current]); // Use cover initial resolution to apply
  // a custom layout that fills the layout

  useEffect(() => {
    if (initialResolutionStrategy !== 'cover') return;
    if (containerRef.current instanceof HTMLElement === false) return;
    const {
      width,
      height
    } = containerRef.current.getBoundingClientRect();
    const updatedResolution = {
      id: 'custom',
      label: 'Custom',
      value: [Math.round(width - CONTAINER_HORIZONTAL_PADDING), Math.round(height * 0.86)]
    };
    setResolution(updatedResolution); // Broadcast updated resolution

    if (onPreviewResolutionChange) {
      onPreviewResolutionChange(updatedResolution);
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [initialResolutionStrategy, containerRef.current]);
  const updatePreviewResolution = useCallback(_ref2 => {
    let {
      resolution,
      width,
      height
    } = _ref2;
    const hasWidth = typeof width === 'number' && width > 0;
    const hasHeight = typeof height === 'number' && height > 0;

    if (!hasWidth && !hasHeight && !resolution) {
      throw Error(`${PREFIX} updatePreviewResolution: must be called with a resolution or a custom width/height`);
    }

    let updatedResolution = resolution;

    if (width && height) {
      updatedResolution = {
        id: 'custom',
        label: 'Custom',
        value: [width, height]
      };
      setResolution(updatedResolution);
    } else if (resolution) {
      setResolution(resolution);
    } // Broadcast updated resolution


    if (updatedResolution && onPreviewResolutionChange) {
      onPreviewResolutionChange(updatedResolution);
    }
  }, [setResolution, onPreviewResolutionChange]);

  const updateActivePreviewGroup = group => {
    setActivePreviewGroup(group);
  };

  const adjustZoomLevel = value => {
    setZoomLevel(zoomLevel + value);
  };

  return {
    previewResolution,
    updatePreviewResolution,
    activePreviewGroup,
    updateActivePreviewGroup,
    hasDiscoveredResolution: Boolean(previewResolution.value[0] && previewResolution.value[1]),
    zoomLevel,
    adjustZoomLevel
  };
} // Find resolution most similar to the
// device's current viewport width

function discoverClosestResolution(previewGroups, maxDisoverWidth, fallback) {
  let targetWidth = 0;

  if (true) {
    targetWidth = window.innerWidth || 0;
  }

  if (typeof document !== 'undefined' && document.documentElement) {
    targetWidth = document.documentElement.clientWidth || 0;
  }

  const allResolutions = flatten(previewGroups.map(_ref3 => {
    let {
      items
    } = _ref3;
    return items;
  }));
  const [smallestWidth] = allResolutions.map(_ref4 => {
    let {
      value
    } = _ref4;
    return value[0];
  }).sort((a, b) => a - b);
  targetWidth = Math.min(targetWidth, Math.max(smallestWidth, maxDisoverWidth)); // Find largest device, less than target width

  const discovered = allResolutions.reduce((acc, resolution) => {
    const [resolutionWidth] = resolution.value;

    if (resolutionWidth <= targetWidth && resolutionWidth > acc.value[0]) {
      return resolution;
    }

    return acc;
  }, EMPTY_RESOLUTION); // Return discovered or fallback

  return discovered.value[0] ? discovered : fallback;
} // Lookup a group by its'
// resolution item


function findResolutionsGroup(previewGroups, resolution) {
  return previewGroups.find(group => group.items.some(_ref5 => {
    let {
      id
    } = _ref5;
    return id === resolution.id;
  }));
}